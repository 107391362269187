/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ROUTES } from '@routes';
import useAWSHelper from './useAWSHelper';

export default () => {
  const { t } = useTranslation('practice');
  const { t: commonT } = useTranslation('common');
  const { getAWSRegionName } = useAWSHelper();
  const getTitle = useCallback(
    ({ CaseId }: Pick<IntelligenceItem, 'CaseId'>): string | undefined => {
      const title = t('title', { returnObjects: true });
      return _.get(title, CaseId);
    },
    [t],
  );
  const getDescription = useCallback(
    ({
      CaseId,
      DetailsValue,
    }: Pick<IntelligenceItem, 'CaseId' | 'DetailsValue'>):
      | string
      | undefined => {
      const texts = t('description', { returnObjects: true });
      if (DetailsValue && _.has(texts, CaseId)) {
        const newDetails = _.cloneDeep(DetailsValue);
        Object.keys(newDetails).forEach((key) => {
          if (key === 'REGION') {
            newDetails[key] = getAWSRegionName(String(newDetails[key]));
          }
        });
        // @ts-ignore
        return t(`description.${CaseId}`, { ...newDetails });
      }
      return _.get(texts, CaseId);
    },
    [t, getAWSRegionName],
  );
  const hasRecommendation = useCallback(
    ({ CaseId }: Pick<IntelligenceItem, 'CaseId'>): boolean => {
      const recommend = t('recommend', { returnObjects: true });
      return _.has(recommend, CaseId);
    },
    [t],
  );
  const getRecommendation = useCallback(
    ({
      CaseId,
      ResourceId,
      DetailsValue,
    }: Pick<IntelligenceItem, 'CaseId' | 'ResourceId' | 'DetailsValue'>):
      | string
      | undefined => {
      const texts = t('recommend', { returnObjects: true });
      if (DetailsValue && _.has(texts, CaseId)) {
        const newDetails = _.cloneDeep(DetailsValue);
        Object.keys(newDetails).forEach((key) => {
          if (key === 'REGION') {
            newDetails[key] = getAWSRegionName(String(newDetails[key]));
          }
        });
        newDetails.RESOURCE_ID = ResourceId;
        // @ts-ignore
        return t(`recommend.${CaseId}`, { ...newDetails });
      }
      return _.get(texts, CaseId);
    },
    [t, getAWSRegionName],
  );
  const getCTAText = useCallback(
    ({ CaseId }: Pick<IntelligenceItem, 'CaseId'>) => {
      switch (CaseId) {
        case 'AWS-EC2-0002':
        case 'AWS-EC2-0003':
        case 'AWS-EC2-0006':
        case 'AWS-EC2-0007':
        case 'AWS-EC2-0009':
        case 'AWS-EC2-0016':
          return t('cta.EC2');
        case 'AWS-EC2-0001':
          return `${t('cta.EC2')} (RI)`;
        case 'AWS-EC2-0008':
          return `${t('cta.EC2')} (AMI)`;
        case 'AWS-EC2-EBS-0001':
        case 'AWS-EC2-EBS-0002':
        case 'AWS-EC2-EBS-0003':
        case 'AWS-EC2-EBS-0004':
        case 'AWS-EC2-EBS-0005':
        case 'AWS-EC2-EBS-0007':
        case 'AWS-EC2-EBS-0008':
        case 'AWS-EC2-EBS-0009':
          return `${t('cta.EC2')} (EBS)`;
        case 'AWS-EC2-0010':
        case 'AWS-EC2-0013':
        case 'AWS-EC2-0020':
          return t('cta.billing');
        case 'AWS-EC2-0018':
        case 'AWS-EC2-0019':
        case 'AWS-Billing-0001':
        case 'AWS-Billing-0007':
        case 'AWS-Billing-0008':
          return t('cta.cost');
        case 'AWS-GENERAL-0002':
          return t('cta.cw');
        case 'AWS-RDS-0001':
        case 'AWS-RDS-0002':
        case 'AWS-RDS-0004':
          return t('cta.RDS');
        case 'AWS-S3-0001':
        case 'AWS-S3-0002':
          return t('cta.S3');
        case 'AWS-EC2-0014':
        case 'AWS-EC2-0015':
        case 'AWS-EC2-0023':
        case 'AWS-EC2-0024':
          return 'Smart Planner';
        default:
          return undefined;
      }
    },
    [t],
  );
  const hasExternalLink = useCallback(({ CaseId }: IntelligenceItem) => {
    switch (CaseId) {
      case 'AWS-EC2-0001':
      case 'AWS-EC2-0002':
      case 'AWS-EC2-0003':
      case 'AWS-EC2-0006':
      case 'AWS-EC2-0007':
      case 'AWS-EC2-0008':
      case 'AWS-EC2-0009':
      case 'AWS-EC2-0010':
      case 'AWS-EC2-0013':
      case 'AWS-EC2-0016':
      case 'AWS-EC2-0018':
      case 'AWS-EC2-0019':
      case 'AWS-Billing-0001':
      case 'AWS-Billing-0007':
      case 'AWS-Billing-0008':
      case 'AWS-EC2-0020':
      case 'AWS-EC2-EBS-0001':
      case 'AWS-EC2-EBS-0002':
      case 'AWS-EC2-EBS-0003':
      case 'AWS-EC2-EBS-0004':
      case 'AWS-EC2-EBS-0005':
      case 'AWS-EC2-EBS-0007':
      case 'AWS-EC2-EBS-0008':
      case 'AWS-EC2-EBS-0009':
      case 'AWS-GENERAL-0002':
      case 'AWS-RDS-0001':
      case 'AWS-RDS-0002':
      case 'AWS-RDS-0004':
      case 'AWS-S3-0001':
      case 'AWS-S3-0002':
        return true;
      default:
        return false;
    }
  }, []);
  const getExternalLink = useCallback(
    (
      {
        CaseId,
        ResourceId,
        DetailsValue,
        ResourcesList,
      }: Pick<
        IntelligenceItem,
        'CaseId' | 'ResourceId' | 'DetailsValue' | 'ResourcesList'
      >,
      { region }: { region?: string } = { region: undefined },
    ) => {
      switch (CaseId) {
        case 'AWS-EC2-0001':
          return `https://console.aws.amazon.com/ec2/home?region=${DetailsValue?.REGION}#ReservedInstances:`;
        case 'AWS-EC2-0002':
        case 'AWS-EC2-0003':
          return `https://console.aws.amazon.com/ec2/home?region=${DetailsValue?.REGION}#InstanceDetails:instanceId=${DetailsValue?.INSTANCEID}`;
        case 'AWS-EC2-0006':
          return `https://console.aws.amazon.com/ec2/home?region=${DetailsValue?.REGION}#Addresses:`;
        case 'AWS-EC2-0007':
          return `https://console.aws.amazon.com/ec2/home?region=${
            DetailsValue?.REGION
          }#LoadBalancers:search=${ResourcesList?.join(
            ',',
          )};sort=loadBalancerName`;
        case 'AWS-EC2-0008':
          return `https://console.aws.amazon.com/ec2/home?region=${
            DetailsValue?.REGION
          }#Images:visibility=owned-by-me;v=3;imageId=${ResourcesList?.join(
            ',',
          )}`;
        case 'AWS-EC2-0009':
          return `https://console.aws.amazon.com/ec2/home?region=${region}#InstanceDetails:instanceId=${DetailsValue?.INSTANCEID}`;
        case 'AWS-EC2-0010':
          return 'https://console.aws.amazon.com/billing/home?skipRegion=true#/paymentsoverview/payments-due';
        case 'AWS-EC2-0013':
          return `https://console.aws.amazon.com/ec2/home?region=${DetailsValue?.REGION}#ReservedInstances:search=${DetailsValue?.RIID}`;
        case 'AWS-EC2-0016':
          return `https://console.aws.amazon.com/ec2/home?region=${DetailsValue?.REGION}#LoadBalancers:sort=loadBalancerName`;
        case 'AWS-EC2-0018':
        case 'AWS-EC2-0019':
        case 'AWS-Billing-0001':
        case 'AWS-Billing-0007':
        case 'AWS-Billing-0008':
          return 'https://console.aws.amazon.com/cost-management/home?#/dashboard';
        case 'AWS-EC2-0020':
          return 'https://us-east-1.console.aws.amazon.com/billing/home?region=us-east-1&skipRegion=true#/paymentsoverview/payments-due';
        case 'AWS-EC2-EBS-0001':
        case 'AWS-EC2-EBS-0002':
        case 'AWS-EC2-EBS-0003':
        case 'AWS-EC2-EBS-0004':
        case 'AWS-EC2-EBS-0005':
          return `https://console.aws.amazon.com/ec2/home?region=${region}#VolumeDetails:volumeId=${ResourceId}`;
        case 'AWS-EC2-EBS-0007':
          return `https://console.aws.amazon.com/ec2/home?region=${DetailsValue?.REGION}#SnapshotDetails:snapshotId=${ResourceId}`;
        case 'AWS-EC2-EBS-0008':
          return `https://console.aws.amazon.com/ec2/home?region=${DetailsValue?.REGION}#Lifecycle:policyType=EBS_SNAPSHOT_MANAGEMENT`;
        case 'AWS-EC2-EBS-0009':
          return `https://console.aws.amazon.com/ec2/home?region=${DetailsValue?.REGION}#Lifecycle:policyType=IMAGE_MANAGEMENT`;
        case 'AWS-GENERAL-0002':
          return 'https://console.aws.amazon.com/cloudwatch/home#home:';
        case 'AWS-RDS-0001':
        case 'AWS-RDS-0002':
        case 'AWS-RDS-0004':
          return `https://console.aws.amazon.com/rds/home?region=${region}#database:id=${DetailsValue?.INSTANCEID};is-cluster=false`;
        case 'AWS-S3-0001':
        case 'AWS-S3-0002':
          return `https://s3.console.aws.amazon.com/s3/buckets/${ResourceId}?tab=objects`;
        default:
          return undefined;
      }
    },
    [],
  );
  const getInternalLink = useCallback(
    ({ CaseId }: Pick<IntelligenceItem, 'CaseId'>) => {
      switch (CaseId) {
        case 'AWS-EC2-0014':
        case 'AWS-EC2-0015':
          return ROUTES.PLANNER.RI;
        case 'AWS-EC2-0023':
        case 'AWS-EC2-0024':
          return ROUTES.PLANNER.SP;
        default:
          return undefined;
      }
    },
    [],
  );
  const getDetailsRoute = useCallback(
    ({
      CaseId,
      ResourceId,
    }: Pick<IntelligenceItem, 'CaseId' | 'ResourceId'>) => {
      switch (CaseId) {
        case 'AWS-EC2-0027':
          return `${ROUTES.DETAILS.COMPUTE}/${ResourceId}?tab=utilization`;
        case 'AWS-EC2-0028':
          return `${ROUTES.DETAILS.COMPUTE}/${ResourceId}?tab=purchasing`;
        case 'AWS-EC2-0002':
        case 'AWS-EC2-0003':
        case 'AWS-EC2-0009':
        case 'AWS-EC2-0019':
          return `${ROUTES.DETAILS.COMPUTE}/${ResourceId}?tab=other`;
        case 'AWS-RDS-0003':
          return `${ROUTES.DETAILS.DATABASE}/${ResourceId}?tab=utilization`;
        case 'AWS-RDS-0001':
        case 'AWS-RDS-0002':
          return `${ROUTES.DETAILS.DATABASE}/${ResourceId}?tab=storage`;
        case 'AWS-RDS-0004':
          return `${ROUTES.DETAILS.DATABASE}/${ResourceId}?tab=purchasing`;
        case 'AWS-S3-0001':
        case 'AWS-S3-0002':
          return `${ROUTES.DETAILS.STORAGE}/${ResourceId}`;
        case 'AWS-EC2-EBS-0001':
        case 'AWS-EC2-EBS-0002':
        case 'AWS-EC2-EBS-0003':
        case 'AWS-EC2-EBS-0004':
        case 'AWS-EC2-EBS-0005':
          return `${ROUTES.DETAILS.VOLUME}/${ResourceId}`;
        case 'AWS-EC2-0006':
        case 'AWS-EC2-0007':
        case 'AWS-EC2-0008':
        case 'AWS-EC2-0016':
        case 'AWS-EC2-0018':
        case 'AWS-EC2-EBS-0007':
        case 'AWS-EC2-EBS-0008':
        case 'AWS-EC2-EBS-0009':
        case 'AWS-GENERAL-0002':
          return ROUTES.MISCELLANEOUS.ROOT;
        case 'AWS-EC2-0001':
          return ROUTES.PLANNER.UNCOVERED;
        case 'AWS-EC2-0004':
        case 'AWS-EC2-0005':
          return ROUTES.PLANNER.RI;
        case 'AWS-EC2-0025':
          return ROUTES.PLANNER.SP;
        case 'AWS-GENERAL-0001':
        case 'AWS-GENERAL-0003':
        case 'AWS-GENERAL-0004':
        case 'AWS-EC2-0010':
        case 'AWS-EC2-0011':
        case 'AWS-EC2-0012':
        case 'AWS-EC2-0013':
        case 'AWS-EC2-0014':
        case 'AWS-EC2-0015':
        case 'AWS-EC2-0020':
        case 'AWS-EC2-0021':
        case 'AWS-EC2-0022':
        case 'AWS-EC2-0023':
        case 'AWS-EC2-0024':
        case 'AWS-Billing-0001':
        case 'AWS-Billing-0007':
        case 'AWS-Billing-0008':
          return ROUTES.NOTIFICATION.ROOT;
        default:
          return undefined;
      }
    },
    [],
  );
  const getCategoryName = useCallback((Category: string) => {
    switch (Category) {
      case 'Utilization':
      case 'PurchasingOptions':
      case 'UnusedResources':
      case 'Notifications':
      case 'Other':
        return t(`category.${Category}`);
      default:
        return Category;
    }
  }, []);
  const getNotificationType = useCallback(
    ({ CaseId }: Pick<IntelligenceItem, 'CaseId'>) => {
      switch (CaseId) {
        case 'AWS-EC2-0001':
        case 'AWS-EC2-0010':
        case 'AWS-EC2-0013':
        case 'AWS-EC2-0014':
        case 'AWS-EC2-0015':
        case 'AWS-EC2-0020':
        case 'AWS-EC2-0023':
        case 'AWS-EC2-0024':
        case 'AWS-Billing-0001':
        case 'AWS-Billing-0007':
        case 'AWS-Billing-0008':
          return commonT('label.actionRequired');
        case 'AWS-EC2-0011':
        case 'AWS-EC2-0012':
        case 'AWS-EC2-0021':
        case 'AWS-EC2-0022':
        case 'AWS-GENERAL-0001':
        case 'AWS-GENERAL-0003':
        case 'AWS-GENERAL-0004':
          return commonT('label.generalAlerts');
        default:
          return undefined;
      }
    },
    [],
  );
  const isGeneralAlert = useCallback(
    ({ CaseId }: Pick<IntelligenceItem, 'CaseId'>) => {
      switch (CaseId) {
        case 'AWS-EC2-0001':
        case 'AWS-EC2-0010':
        case 'AWS-EC2-0013':
        case 'AWS-EC2-0014':
        case 'AWS-EC2-0015':
        case 'AWS-EC2-0020':
        case 'AWS-EC2-0023':
        case 'AWS-EC2-0024':
        case 'AWS-Billing-0001':
        case 'AWS-Billing-0007':
        case 'AWS-Billing-0008':
          return false;
        case 'AWS-EC2-0011':
        case 'AWS-EC2-0012':
        case 'AWS-EC2-0021':
        case 'AWS-EC2-0022':
        case 'AWS-GENERAL-0001':
        case 'AWS-GENERAL-0003':
        case 'AWS-GENERAL-0004':
        default:
          return true;
      }
    },
    [],
  );
  return {
    getTitle,
    getDescription,
    hasRecommendation,
    getRecommendation,
    getCTAText,
    hasExternalLink,
    getExternalLink,
    getInternalLink,
    getDetailsRoute,
    getCategoryName,
    getNotificationType,
    isGeneralAlert,
  };
};
