import React, { useMemo } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import {
  PlannerCircularChart,
  PlannerLinearChart,
} from '@components/chart/planner';
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import {
  PreviewCoverageLegend,
  SimulationHeader,
} from '@pages/private/planner/simulation/Simulation.styled';
import {
  getPercentageProportion,
  subtractPercentageChanges,
  subtractPercentageProportions,
  ValueFormatter,
} from '@utils';
import shadows from '@theme/shadows';
import { primary } from '@theme/colors';
import { useRecoilValue } from 'recoil';
import { simulateRequestState } from '@pages/private/planner/simulation/Simulation.atoms';
import { isReservedResponse } from '@pages/private/planner/simulation/Simulation.utils';
import { useTranslation } from 'react-i18next';

const SimulatedResult: React.FC<{
  isLoading: boolean;
  data: CCQueryResponse<SimulationResult>;
}> = ({ isLoading, data }) => {
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={20}>
        <CircularProgress size={96} />
      </Box>
    );
  }
  if (!data?.items?.length) {
    return null;
  }
  const { t } = useTranslation('planner');
  const { t: optT } = useTranslation('optimization');
  const request = useRecoilValue(simulateRequestState);
  const { CurrentUsage, SimulatedUsage } = data.items[0];
  const MonthlyRecurring = useMemo<number>(() => {
    if (request && request.DiscountProgram.PaymentOption !== 'All Upfront') {
      if (isReservedResponse(SimulatedUsage)) {
        return SimulatedUsage.SimulatedRate * 720.0;
      }
      return SimulatedUsage.SimulatedCommitment * 720.0;
    }
    return 0;
  }, [SimulatedUsage, request]);
  const TotalCost = useMemo<number>(() => {
    if (request?.DiscountProgram?.Duration) {
      return (
        request.DiscountProgram.Duration * 12 * MonthlyRecurring +
        SimulatedUsage.UpfrontFee
      );
    }
    return 0;
  }, [request, SimulatedUsage, MonthlyRecurring]);
  return (
    <>
      <Grid container columnSpacing={10} mt={5}>
        <Grid item lg={6} xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t('label.changeCoverage')}
          </Typography>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <PlannerCircularChart
                size="small"
                data={[
                  {
                    cost: CurrentUsage.Ondemand,
                  },
                  {
                    cost: CurrentUsage.SavingsPlan,
                  },
                  {
                    cost: CurrentUsage.Reserved,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={2}>
              <ArrowRightIcon />
            </Grid>
            <Grid item xs={6}>
              <PlannerCircularChart
                size="medium"
                data={[
                  {
                    cost: SimulatedUsage.NormalizedOndemandUsage,
                  },
                  {
                    cost: SimulatedUsage.NormalizedSavingsPlanUsage,
                  },
                  {
                    cost: SimulatedUsage.NormalizedReservedUsage,
                  },
                ]}
              />
            </Grid>
          </Grid>
          <PreviewCoverageLegend
            items={[
              {
                label: optT('label.common.od'),
                color: '#FF7976',
                before: ValueFormatter.toCurrency(CurrentUsage.OndemandCost),
                after: ValueFormatter.toCurrency(SimulatedUsage.OndemandCost),
                change: ValueFormatter.toCurrency(
                  SimulatedUsage.OndemandCost - CurrentUsage.OndemandCost,
                ),
                increased:
                  SimulatedUsage.OndemandCost - CurrentUsage.OndemandCost >=
                  0.0,
              },
              {
                label: optT('label.common.ri_coverage'),
                color: '#57CAEB',
                before: ValueFormatter.toPercent(
                  getPercentageProportion(
                    CurrentUsage.Reserved,
                    CurrentUsage.TotalUsage,
                  ),
                ),
                after: ValueFormatter.toPercent(
                  getPercentageProportion(
                    SimulatedUsage.NormalizedReservedUsage,
                    SimulatedUsage.TotalNormalizedUsage,
                  ),
                ),
                change: ValueFormatter.toPercent(
                  subtractPercentageProportions(
                    SimulatedUsage.NormalizedReservedUsage,
                    SimulatedUsage.TotalNormalizedUsage,
                    CurrentUsage.Reserved,
                    CurrentUsage.TotalUsage,
                  ),
                ),
                increased:
                  subtractPercentageChanges(
                    SimulatedUsage.NormalizedReservedUsage,
                    SimulatedUsage.TotalNormalizedUsage,
                    CurrentUsage.Reserved,
                    CurrentUsage.TotalUsage,
                  ) >= 0.0,
              },
              {
                label: optT('label.common.sp_coverage'),
                color: '#9594FF',
                before: ValueFormatter.toPercent(
                  getPercentageProportion(
                    CurrentUsage.SavingsPlan,
                    CurrentUsage.TotalUsage,
                  ),
                ),
                after: ValueFormatter.toPercent(
                  getPercentageProportion(
                    SimulatedUsage.NormalizedSavingsPlanUsage,
                    SimulatedUsage.TotalNormalizedUsage,
                  ),
                ),
                change: ValueFormatter.toPercent(
                  subtractPercentageProportions(
                    SimulatedUsage.NormalizedSavingsPlanUsage,
                    SimulatedUsage.TotalNormalizedUsage,
                    CurrentUsage.SavingsPlan,
                    CurrentUsage.TotalUsage,
                  ),
                ),
                increased:
                  subtractPercentageChanges(
                    SimulatedUsage.NormalizedSavingsPlanUsage,
                    SimulatedUsage.TotalNormalizedUsage,
                    CurrentUsage.SavingsPlan,
                    CurrentUsage.TotalUsage,
                  ) >= 0.0,
              },
            ]}
          />
        </Grid>
        <Grid item lg={6} xs={12} mt={{ lg: 0, xs: 7 }}>
          <Typography variant="body2" color="textSecondary">
            {t('label.changeCost')}
          </Typography>
          <PlannerLinearChart
            data={[
              {
                type: 'OD',
                before: CurrentUsage.OndemandCost,
                after: SimulatedUsage.OndemandCost,
              },
              {
                type: 'RI',
                before: CurrentUsage.ReservedCost,
                after: SimulatedUsage.ReservedCost,
              },
              {
                type: 'SP',
                before: CurrentUsage.SavingsPlanCost,
                after: SimulatedUsage.SavingsPlanCost,
              },
            ]}
          />
        </Grid>
      </Grid>
      <SimulationHeader
        Title={`${
          isReservedResponse(SimulatedUsage)
            ? optT('label.common.ri')
            : optT('label.common.sp')
        } ${t('label.purchaseSummary')}`}
        mt={10}
        mb={3}
      />
      <Box borderRadius="4px" boxShadow={shadows[1]}>
        {request &&
          SimulatedUsage &&
          (isReservedResponse(SimulatedUsage) ? (
            <>
              <Box display="flex" justifyContent="space-between" p={2}>
                <Typography variant="body2">{t('label.totalDue')}</Typography>
                <Typography variant="body2">
                  {ValueFormatter.toCurrency(SimulatedUsage.UpfrontFee)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" p={2}>
                <Typography variant="body2">
                  {t('label.monthlyRecurring')}
                </Typography>
                <Typography variant="body2">
                  {ValueFormatter.toCurrency(MonthlyRecurring)}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                p={2}
                bgcolor={primary[50]}
              >
                <Typography variant="subtitle2">
                  {t('label.totalCost')}
                </Typography>
                <Typography variant="subtitle2" color="primary">
                  {ValueFormatter.toCurrency(TotalCost)}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between" p={2}>
                <Typography variant="body2">
                  {t('label.upfrontCost')}
                </Typography>
                <Typography variant="body2">
                  {ValueFormatter.toCurrency(SimulatedUsage.UpfrontFee)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" p={2}>
                <Typography variant="body2">
                  {t('label.monthlyPayment')}
                </Typography>
                <Typography variant="body2">
                  {ValueFormatter.toCurrency(MonthlyRecurring)}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                p={2}
                bgcolor={primary[50]}
              >
                <Typography variant="subtitle2">
                  {t('label.totalCost')}
                </Typography>
                <Typography variant="subtitle2" color="primary">
                  {ValueFormatter.toCurrency(TotalCost)}
                </Typography>
              </Box>
            </>
          ))}
      </Box>
    </>
  );
};

export default SimulatedResult;
